/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import HeadingSection from "~components/sections/HeadingSection.jsx";
import ImageArticleSection from "~components/sections/ImageArticleSection.jsx";
import InstagramSection from "~components/sections/InstagramSection.jsx";
import TeamGridSection from "~components/sections/TeamGridSection.jsx";
import Footer from "~components/Footer.jsx";
import Layout from "~components/Layout.jsx";
import SEO from "~components/SEO.jsx";

const TeamPage = ({ data, location }) => {
  // ===========================================================================
  // variables

  const cms = data.sanityTeamPage;

  // ===========================================================================
  // render

  return (
    <>
      <SEO
        customTitle={cms?.title}
        customDescription={cms?.seoDescription}
        customKeywords={cms?.seoKeywords}
        path={location.pathname}
      />

      <Layout
        _css={[
          css`
            margin-top: 60px;
          `,
          tw`min-h-[90vh] relative`
        ]}
      >
        <HeadingSection data={cms.headerSectionOne} />

        <TeamGridSection
          data={{ members: cms.members, placeholder: cms.teamPlaceholder }}
        />

        <HeadingSection data={cms.headerSectionTwo} />

        {cms.board.map((boardMember) => (
          <ImageArticleSection data={boardMember} />
        ))}

        <InstagramSection />
      </Layout>

      <Footer />
    </>
  );
};

export default TeamPage;

export const query = graphql`
  query TeamPage {
    sanityTeamPage {
      title

      #

      headerSectionOne {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        subheader
      }

      #

      members {
        _key
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        name
        title
        favourite
        image {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
          hotspot {
            y
            x
          }
        }
      }

      #

      teamPlaceholder {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        image {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }

      #

      headerSectionTwo {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        subheader
      }

      #

      board {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        _rawBody
        image {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        imageAlign
      }

      #

      seoDescription
      seoKeywords
    }
  }
`;
